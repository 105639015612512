import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import { radeFirestore } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import attachmentLogo from "../../assets/attatchmentLogo.svg";
import styles from "./index.module.scss";
import KYCStatusSwitchPopUp from "../KYCStatusSwitchPopUp/KYCStatusSwitchPopUp";
import Spinner from "../../components/Spinner/Spinner";
import UserDetails from "./UserDetails";
const RadeUserKYC = () => {
  const [PZUserdata, setPZUserData] = useState([]); //state to store the user data
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [modalIsOpen, setModalIsOpen] = useState(false); //state to open and close the modal
  const [pageSize, setPageSize] = useState(10); //state to store the page size for pagination
  const [searchTerm, setSearchTerm] = useState(""); //state to store the serach term
  const [selectedKYCId, setSelectedKYCId] = useState(null); //state to store the selected KYC Id
  const [loadingStates, setLoadingStates] = useState({}); // Track loading state for each row
  const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user
  const [KYCRejectionReason, setKYCRejectionReason] = useState(null);
  // Function that handles the saerch bar
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  // Function to handle row click and set the selected user
  const handleRowClick = (user) => {
    setSelectedUser(user);
    setSelectedKYCId(user.id);
  };
  // Function to go back to the table view
  const handleBackButtonClick = () => {
    setSelectedUser(null);
  };
  //Fetch he userData collection to fetch the user emails that has support tickets
  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(radeFirestore, "userData")
      );
      const result = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Access the document ID
        data: doc.data(), // Access the document data
      }));
      setPZUserData(result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getPZUserData();
  }, [modalIsOpen]);
  // Function to handle the filtering of data using the email
  const filteredData = useMemo(() => {
    return PZUserdata.filter(
      (post) =>
        post.data.email &&
        post.data.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [PZUserdata, searchTerm]);

  //Function to handle the page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //Function to switch the KYC status
  const handleClickStatusSwitch = () => {
    setModalIsOpen(true);
  };
  //Function to update the KYC status in the firebase
  const handleKYCStatusUpdate = async (status) => {
    try {
      if (!selectedKYCId) {
        console.log("No KYC document selected.");
        return;
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: true, // Set loading state for the selected row to true
      }));

      const kycDocRef = doc(radeFirestore, "userData", selectedKYCId);
      await updateDoc(kycDocRef, {
        kycStatus: status,
        KYCRejectionReason: KYCRejectionReason,
      });

      console.log("KYC status updated successfully!");

      await getPZUserData();

      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: false, // Set loading state for the selected row to false
      }));
    } catch (error) {
      console.log("Error updating KYC status:", error);
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: false, // Set loading state for the selected row to false in case of an error
      }));
    }
  };

  //Function for pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);
  console.log("paginated data", paginatedData);

  if (paginatedData.length < 1) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    return (
      <>
        {" "}
        {!selectedUser ? (
          <div>
            <div className={styles.transferOrders}>
              <div className={styles.refreshButtonContainer}>
                <div className={styles.searchBarDiv}>
                  <input
                    type="text"
                    className={styles.searchBar}
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <img
                    src={searchIcon}
                    className={styles.searchIcon}
                    alt="searchIcon"
                  />
                </div>
                <ButtonRade
                  customStyling={styles.refreshButton}
                  onClick={getPZUserData}
                >
                  {" "}
                  <img
                    src={refreshIcon}
                    alt="refreshIcon"
                    className={styles.refreshIcon}
                  />
                </ButtonRade>
              </div>
              <div className={styles.searchContainer}>
                <div className={styles.search}>
                  Show
                  <input
                    type="number"
                    className={styles.searchPage}
                    min={1}
                    value={pageSize}
                    onChange={(e) => setPageSize(parseInt(e.target.value))}
                  />
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={filteredData.length}
                  pageSize={pageSize}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
            <div>
              <table className={styles.tableContainer}>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>{PZ_USER_ENUM.firstName}</th>
                    <th>{PZ_USER_ENUM.email}</th>
                    <th>KYC Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((user) => (
                    <tr
                      className={styles.tableRow}
                      key={user.data.email}
                      onClick={() => handleRowClick(user)}
                    >
                      <td>{user.data.userName || "-"}</td>
                      <td>{user.data.firstName || "-"}</td>
                      <td>{user.data.email || "-"}</td>
                      <td>{user.data.kycStatus || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Modal
              isOpen={modalIsOpen}
              overlayClassName={styles.popupOverlay}
              className={styles.popupContent}
              shouldCloseOnOverlayClick={false}
              ariaHideApp={false}
            >
              <KYCStatusSwitchPopUp
                setModalIsOpen={setModalIsOpen}
                handleKYCStatusUpdate={handleKYCStatusUpdate}
                selectedKYCId={selectedKYCId}
              />
            </Modal>
          </div>
        ) : (
          <div>
            <UserDetails
              user={selectedUser}
              handleClickStatusSwitch={handleClickStatusSwitch}
              loadingStates={loadingStates}
              selectedKYCId={selectedKYCId}
              handleBackButtonClick={handleBackButtonClick}
              setKYCRejectionReason={setKYCRejectionReason}
            />

            <Modal
              isOpen={modalIsOpen}
              overlayClassName={styles.popupOverlay}
              className={styles.popupContent}
              shouldCloseOnOverlayClick={false}
              ariaHideApp={false}
            >
              <KYCStatusSwitchPopUp
                setModalIsOpen={setModalIsOpen}
                handleKYCStatusUpdate={handleKYCStatusUpdate}
                selectedKYCId={selectedKYCId}
                setKYCRejectionReason={setKYCRejectionReason}
                KYCRejectionReason={KYCRejectionReason}
              />
            </Modal>
          </div>
        )}
      </>
    );
  }
};

export default RadeUserKYC;
