export const formatUserRewardsData = (userDetailsData) => {
  const formattedList = [];

  for (const [key, value] of Object.entries(
    userDetailsData.userDetailsRewardsData
  )) {
    const obj = {};
    const length =
      userDetailsData.userDetailsRewardsData[key].userVestedDetails.length;

    if (
      value.userVestedDetails &&
      value.userVestedDetails.userVests &&
      value.userVestedDetails.userVests.length >= 0
    ) {
      for (let i = 0; i <= length; i++) {
        if (value.userVestedDetails.userVests[i]) {
          obj.account = value.userVestedDetails[0];
          obj.userVestAmount = Number(value.userVestedAmount);
          obj.lastRewardTime = parseInt(
            value.userVestedDetails.userVests[i].lastRewardTime._hex,
            16
          );

          obj.stakeAmount = parseInt(
            value.userVestedDetails.userVests[i].stakeAmount._hex,
            16
          );
          obj.stakeDuration =
            value.userVestedDetails.userVests[i].stakeDuration;
          obj.vestTimestamp = parseInt(
            value.userVestedDetails.userVests[i].timestamp._hex,
            16
          );
          obj.totalRewardsPossible = parseInt(
            value.userVestedDetails.userVests[i].totalRewardsPossible._hex,
            16
          );
          obj.unStakeTime = parseInt(
            value.userVestedDetails.userVests[i].unStakeTime._hex,
            16
          );
          obj.vestingDuration = parseInt(
            value.userVestedDetails.userVests[i].vestingDuration._hex,
            16
          );

          formattedList.push(obj);
        }
      }
    }
  }
  return formattedList;
};
