import { ethers } from "ethers";
import { useState, useEffect } from "react";
import StakingContract from "../../../src/artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";

const provider = new ethers.providers.Web3Provider(window.ethereum);
const RudStakingContract = new ethers.Contract(
  StakingContract.address,
  StakingContract.abi,
  provider
);

const useStakedUserDetails = (userIds, interval = null) => {
  const [userDetailsData, setUserDetailsData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStakedUserDetails = async () => {
      if (RudStakingContract && userIds) {
        const userStakedDetails = {};

        for (const userId of userIds) {
          if (userId) {
            const stakedUserDetails = await RudStakingContract.getUserDetails(
              userId
            );
            const totalNests =
              await RudStakingContract.getTotalNumStakingAccounts();
            userStakedDetails[userId] = {
              userDetails: stakedUserDetails,
              stakedRudBalance: ethers.utils.formatEther(
                stakedUserDetails.totalStakedAmount
              ),
              totalNumOfNests: totalNests,
            };
          }
        }

        setUserDetailsData(userStakedDetails);
        setLoading(false);
      }
    };

    // if (userIds.length > 0) {
    clearInterval(interval);
    interval = setInterval(getStakedUserDetails, 1000);
    // }

    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [userIds]);

  return {
    userDetailsData,
    loading,
  };
};

export default useStakedUserDetails;
