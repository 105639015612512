import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import RaritiUsdContract from "../../../src/artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";
import StakingContract from "../../../src/artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";
/*global BigInt */
const provider = new ethers.providers.Web3Provider(window.ethereum);

const RUDContract = new ethers.Contract(
  RaritiUsdContract.address, //RaritiUSD deployed contract address
  RaritiUsdContract.abi, //RaritiUSD deployed contract abi
  provider
);
let interval = null;
const useRudBalanceInContract = () => {
  //state to set the connected user's RUD Balance
  const [contractRUDBalance, setContractRUDBalance] = useState(null);
  //function to get the RUD balance using
  const getRudBalance = async () => {
    if (RUDContract) {
      const RUDBalance = await RUDContract.balanceOf(StakingContract.address);
      setContractRUDBalance(RUDBalance);
    } else {
      setContractRUDBalance(undefined);
    }
  };
  useEffect(() => {
    getRudBalance();
    interval = setInterval(getRudBalance, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, []);
  if (contractRUDBalance) {
    return {
      contractRudBalance: ethers.utils.formatEther(
        BigInt(contractRUDBalance._hex)
      ),
      loading: false,
    };
  } else {
    return {
      contractRudBalance: null,
      loading: true,
    };
  }
};
export default useRudBalanceInContract;
