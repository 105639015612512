import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import RudBorrowContract from "../../../artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import { formatBorrowerLiquidatingNests } from "../LiquidateNestsFormat";
import Spinner from "../../../components/Spinner/Spinner";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const provider = new ethers.providers.Web3Provider(window.ethereum);

//borrow Rud Contract
const borrowContract = new ethers.Contract(
  RudBorrowContract.address,
  RudBorrowContract.abi,
  provider
);

const BorrowersPaymentDefault = (interval = null) => {
  const { isMobile } = useContext(AppContext);
  const [tokenName, setTokenName] = useState(null);
  const [formattedData, setFormattedData] = useState(null);
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { tokenName } = getNetworkDetails(chainID);

        setTokenName(tokenName);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [tokenName]);

  useEffect(() => {
    const fetchData = async () => {
      if (borrowContract) {
        const latestBlock = await provider.getBlock("latest");
        const liquidatingNests = await borrowContract.getLiquidatingNests();
        setFormattedData(
          formatBorrowerLiquidatingNests(
            liquidatingNests,
            latestBlock.timestamp
          )
        );
      } else {
        console.log("loading");
      }
    };
    fetchData(); // Call the async function immediately
  }, [formattedData]);

  const CardBody = () => (
    <>
      <div className={styles.returnUserBorrowTable}>
        {formattedData.some((item) => item.defaultValue !== 0) ? (
          <table className={styles.tableHeading}>
            <thead>
              <tr
                className={cx(styles.borrowTableHeading, {
                  [styles.borrowTableHeadingWeb]: !isMobile,
                  [styles.borrowTableHeadingMob]: isMobile,
                })}
              >
                <th>{LIQUIDATE_ENUM.userName}</th>
                <th>{LIQUIDATE_ENUM.borrowAndColl}</th>
                <th>{LIQUIDATE_ENUM.loanBalance}</th>
                <th>{LIQUIDATE_ENUM.collateralRatio}</th>
                <th>{LIQUIDATE_ENUM.defaultValue}</th>
                <th>{LIQUIDATE_ENUM.liquidateCollateral}</th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((item) =>
                item.defaultValue !== 0 ? (
                  <tr className={styles.borrowTableNest1}>
                    <td>{item.user}</td>
                    <td>
                      {Number(item.borrowedRUD).toFixed(MAX_DECIMAL_PLACE)}/
                      {Number(item.collateralAmount / ETHER_CONVERSION).toFixed(
                        MAX_DECIMAL_PLACE
                      )}
                    </td>
                    <td>
                      {Number(item.loanBalance).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    <td className={styles.safeCollRatio}>{item.collRatio}%</td>
                    <td>
                      {Number(item.defaultValue).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    <td>
                      {Number(item.liquidateColl / ETHER_CONVERSION).toFixed(
                        MAX_DECIMAL_PLACE
                      )}
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        ) : (
          <div className={styles.noPaymentsDefaualtsContent}>
            {LIQUIDATE_ENUM.noPaymentDefaults}
          </div>
        )}
      </div>
    </>
  );
  if (!formattedData || !tokenName) {
    return <Spinner />;
  }
  return (
    <div
      className={cx(styles.returnUsermainDiv, {
        [styles.returnUsermainDivWeb]: !isMobile,
        [styles.returnUsermainDivMob]: isMobile,
      })}
    >
      <CardBody />
    </div>
  );
};

export default BorrowersPaymentDefault;
