import { APP_ENUM } from "../../enums/appEnum";

const getNetworkDetails = (chainID) => {
  switch (chainID) {
    case "1":
      return {
        networkName: APP_ENUM.ethNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressMainnet,
        tokenName: APP_ENUM.eth,
      };
    case "5":
      return {
        networkName: APP_ENUM.goerliTestNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressGoerli,
        tokenName: APP_ENUM.goerliEth,
      };
    case "31337":
      return {
        networkName: APP_ENUM.hardhatNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressGoerli, // gorli fork
        tokenName: APP_ENUM.eth,
      };
    case "80001":
      return {
        networkName: APP_ENUM.mumbaiNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressMumbai,
        tokenName: APP_ENUM.mumbaiToken,
      };
    default:
      return {
        networkName: undefined,
        priceFeedAddress: undefined,
        tokenName: APP_ENUM.eth,
      };
  }
};
export default getNetworkDetails;
