import styles from "./index.module.scss";
import { ethers } from "ethers";
import moment from "moment";
/*global BigInt */
const NestDetails = ({
  nextPaymentAmount,
  nextPaymentDue,
  remainingNumOfTerm,
}) => {
  const CardBody = () => {
    return (
      <div className={styles.stakeDetailsContainer}>
        <div className={styles.detailsDiv}>
          <div className={styles.stakedRUDInfo}>
            <div className={styles.detailsHeading}>Nex payment amount</div>
            <div className={styles.stakeDetailsValue}>
              {ethers.utils.formatEther(BigInt(nextPaymentAmount))}
              RUD
            </div>
          </div>

          <div className={styles.vestingSchedule}>
            <div className={styles.detailsHeading}>Next payment due</div>
            <div className={styles.stakeDetailsValue}>{nextPaymentDue}</div>
          </div>

          <>
            <div className={styles.maturityDate}>
              <div className={styles.detailsHeading}>
                Remaining number of terms
              </div>
              <div className={styles.stakeDetailsValue}>
                {remainingNumOfTerm}
              </div>
            </div>
          </>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.stakeDetailsContainerMain}>
        {/* calling as a function to avoid flickering issue */}
        {CardBody()}
      </div>
    </>
  );
};

export default NestDetails;
