import React from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../context";
import { useContext } from "react";
import styles from "./index.module.scss";
import { useState } from "react";
import { RETURN_USER_STAKING } from "../../enums/returnUserStaking";
import { ethers } from "ethers";
import { useEffect } from "react";
import { radeFirestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { formatUserDetailsData } from "./formatUserStaking";
import useStakedUserDetails from "../../Hooks/useStakedUserDetails/useStakedUserDetails";
import useRADEInWallet from "../../Hooks/useRADEVestDetails/useRADEVestDetails";
import { formatUserRewardsData } from "./formatUserRewards";
import Spinner from "../../components/Spinner/Spinner";
import StakeDetails from "../StakeDetails/StakeDetails";
import TableCard from "../../components/TableCard/TableCard";
// import useRADEInWallet from "../../Hooks/useRADEVestDetails/useRADEVestDetails";
const StakedUserDetails = () => {
  const [userIds, setUserIds] = useState([]); // state to store the user IDs

  //State variable to set apppropriate error message accordingly
  // Assigning variable to totalStakedRUDValue inorder to display unstake button
  const userDetailsData = useStakedUserDetails(userIds);
  const [formattedStakeData, setFormattedStakeData] = useState(null);
  const [formattedRewardData, setFormattedRewardData] = useState(null);
  const [lockDuration, setLockduration] = useState(null);
  const [maturityDate, setMaturityDate] = useState(null);
  const [maturityDateInSec, setMaturityDateInSec] = useState(null);
  const [stakedAmount, setStakedAmount] = useState(null);
  const [stakedTime, setStakedTime] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [status, setStatus] = useState(null);
  const [accruedRewards, setAccruedRewards] = useState(null);
  const userDetailsRewardsData = useRADEInWallet(userIds);

  // State variable to keep track of the currently expanded row index
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const secondsToMonths = (seconds) => {
    const secondsInAMonth = 30 * 24 * 60 * 60; // Assuming a month has 30 days
    const months = seconds / secondsInAMonth;

    if (months < 1) {
      const hours = seconds / 3600; // 1 hour = 3600 seconds
      return `${hours.toFixed(0)} Hour`;
    }

    return `${months.toFixed(0)} Months`;
  };

  const epochToDate = (epoch) => {
    const date = new Date(epoch * 1000);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(radeFirestore, "userData")
      );

      const result = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Access the document ID
        data: doc.data(), // Access the document data
      }));
      const userIds = result.map((item) => item.id);
      setUserIds(userIds);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getPZUserData();
  }, []);
  useEffect(() => {
    if (userIds.length > 0) {
      setFormattedStakeData(formatUserDetailsData(userDetailsData));
    }
  }, [userIds, userDetailsData]);

  useEffect(() => {
    if (userIds.length > 0) {
      setFormattedRewardData(formatUserRewardsData(userDetailsRewardsData));
    }
  }, [userIds, userDetailsRewardsData]);

  const handleToggleStakeDetails = (index) => {
    // Toggle the visibility of the stake details for the clicked row
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
    // setRowClicked(true); // Set rowClicked to true when a row is clicked
  };
  const toggleStakeDetails = (index) => {
    if (expandedRowIndex === index) {
      // If it's already expanded, collapse it
      setExpandedRowIndex(null);
    } else {
      // Otherwise, expand it
      setExpandedRowIndex(index);
    }
  };

  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <div
      className={cx(styles.returnUserMainDiv, {
        [styles.returnUserMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.myInvestmentHeading, {
          [styles.myInvestmentHeadingMob]: isMobile,
        })}
      >
        Stake Details
      </div>
    </div>
  );

  const CardBody = () => (
    <>
      <div
        className={cx(styles.returnUserStakeTable, {
          [styles.returnUserStakeTablemob]: isMobile,
        })}
      >
        <table className={styles.tableHeading}>
          <thead>
            {/* {!rowClicked && ( */}
            <tr
              className={cx(styles.stakeTableHeading, {
                [styles.stakeTableHeadingWeb]: !isMobile,
                [styles.stakeTableHeadingMob]: isMobile,
              })}
            >
              <th>{RETURN_USER_STAKING.acccout}</th>
              <th>{RETURN_USER_STAKING.stakedRUD}</th>
              <th>{RETURN_USER_STAKING.lockDuration}</th>
              <th>{RETURN_USER_STAKING.maturityDate}</th>
              <th>{RETURN_USER_STAKING.status}</th>
              {!isMobile && <th></th>}
            </tr>
            {/* )} */}
          </thead>

          <tbody>
            {formattedStakeData.map((item, index) =>
              item.stakedRUD !== 0 ? (
                <React.Fragment key={item.index}>
                  <tr
                    className={cx(styles.stakeTableNest1, {
                      [styles.rowHidden]: expandedRowIndex === index,
                    })}
                    onClick={() =>
                      handleToggleStakeDetails(
                        index,
                        setAccountNumber(item.account),
                        setStakedAmount(item.stakedRUD),
                        setLockduration(secondsToMonths(item.lockDuration)),
                        setMaturityDate(epochToDate(item.maturityDate)),
                        setStakedTime(item.stakedTime),
                        setStatus(item.status),
                        setMaturityDateInSec(item.maturityDateInSeconds)
                      )
                    }
                  >
                    <td>
                      {item.account.slice(0, 6)}...{item.account.slice(-4)}
                    </td>
                    <td>{Number(item.stakedRUD)}</td>
                    <td>{secondsToMonths(item.lockDuration)}</td>
                    {}
                    {!isMobile && <td>{epochToDate(item.maturityDate)} </td>}
                    <td
                      className={cx(styles.locked, {
                        [styles.unlocked]: item.status === true,
                      })}
                    >
                      {item.status === true ? <>Unlocked</> : <>Locked</>}
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td className={styles.accordionTd} colSpan="7">
                        <StakeDetails
                          expandedRowIndex={expandedRowIndex}
                          toggleStakeDetails={toggleStakeDetails}
                          stakedAmount={stakedAmount}
                          lockDuration={lockDuration}
                          maturityDate={maturityDate}
                          stakedTime={stakedTime}
                          stakeId={index}
                          // acrrRewards={acrrRewards}
                          // setAccrRewards={setAccrRewards}
                          accountNumber={accountNumber}
                          status={status}
                          maturityDateInSec={maturityDateInSec}
                          setStakedAmount={setStakedAmount}
                          formattedRewardData={formattedRewardData}
                          accruedRewards={accruedRewards}

                          // setRowClicked={setRowClicked}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    </>
  );
  if (!formattedStakeData || userIds.length < 1 || !userDetailsData) {
    return <Spinner />;
  }
  return (
    <>
      <div>
        <div
          className={cx(styles.mainDiv, {
            [styles.mainDivWeb]: !isMobile,
            [styles.mainDivMob]: isMobile,
          })}
        >
          <div className={styles.returnUserStatisticsDiv}>
            <TableCard
              titleComponent={<CardTitle />}
              // calling as a function to avoid flickering issue

              bodyComponent={CardBody()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default StakedUserDetails;
