import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import RaritiUsdContract from "../../artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";

/*global BigInt */
const provider = new ethers.providers.Web3Provider(window.ethereum);

const RUDContract = new ethers.Contract(
  RaritiUsdContract.address, //RaritiUSD deployed contract address
  RaritiUsdContract.abi, //RaritiUSD deployed contract abi
  provider
);
let currAcc = null;
const useRudInWallet = (account, interval = null) => {
  //state to set the connected user's RUD Balance
  const [userRUDBalance, setUserRUDBalance] = useState(null);

  useEffect(() => {
    const getRudBalance = async () => {
      if (RUDContract && account) {
        const accountRUDBalance = await RUDContract.balanceOf(account);

        setUserRUDBalance(accountRUDBalance);
      } else {
        setUserRUDBalance(undefined);
      }
    };
    if (account !== currAcc) {
      clearInterval(interval);
      currAcc = account;
    }
    interval = setInterval(getRudBalance, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAcc = null;
    };
  }, [account]);
  if (userRUDBalance) {
    return {
      rudBalance: ethers.utils.formatEther(BigInt(userRUDBalance._hex)),
    };
  } else {
    return {
      rudBalance: null,
    };
  }
};
export default useRudInWallet;
