import React, { useEffect, useState, useMemo, useRef } from "react";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import { radeFirestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import useBorrowedUserDetails from "../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import useRADEInWallet from "../../Hooks/useRADEVestDetails/useRADEVestDetails";
import { formatUserRewardsData } from "../userStakeDetails/formatUserRewards";

import styles from "./index.module.scss";
import Spinner from "../../components/Spinner/Spinner";
import { ethers } from "ethers";
import useStakedUserDetails from "../../Hooks/useStakedUserDetails/useStakedUserDetails";
import UserBorrowedDetails from "./UserBorrowedDetails";
import UserStakedDetails from "./UserStakedDetails";
/*global BigInt */

const RadeUsers = () => {
  const MAX_DECIMAL_PLACES = 2;
  const [PZUserdata, setPZUserData] = useState([]); //state to store the user data
  const [formattedRewardData, setFormattedRewardData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [modalIsOpen, setModalIsOpen] = useState(false); //state to open and close the modal
  const [pageSize, setPageSize] = useState(10); //state to store the page size for pagination
  const [searchTerm, setSearchTerm] = useState(""); //state to store the serach term
  const [selectedKYCId, setSelectedKYCId] = useState(null); //state to store the selected KYC Id
  const [userIds, setUserIds] = useState([]); // state to store the user IDs
  // State variable to identify the current clicked scene (USD/ETH)
  const [titleButtonVal, setTitleButtonVal] = useState("Borrowers");
  const userDetailsRewardsData = useRADEInWallet(userIds);
  const borrowedUserDetails = useBorrowedUserDetails(userIds); // Function that handles the saerch bar
  const userDetails = useStakedUserDetails(userIds);
  const [selectedStakedUser, setSelectedStakedUser] = useState(null);
  const [selectedBorrowedUser, setSelectedBorrowedUser] = useState(null);

  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const handleStakeDetailsRowClick = (user, index) => {
    console.log("user", user);

    setSelectedStakedUser(user);
    setSelectedUserIndex(index);
  };
  useEffect(() => {
    console.log("selectedStakedUser", selectedStakedUser);
    console.log("selectedBorrowedUser", selectedBorrowedUser);
  }, [selectedStakedUser, selectedBorrowedUser]);
  const handleBorrowDetailsRowClick = (user, index) => {
    setSelectedBorrowedUser(user);

    setSelectedUserIndex(index);
  };
  // const userVestedInfos = useRADEInWallet(userIds);
  // Function to format timestamp for display
  const formatTimestamp = (timestamp) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    // Convert Firestore timestamp to JavaScript Date object
    const date = timestamp.toDate();

    // Get hours in 12-hour format and determine AM or PM
    let hours = date.getHours();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Format the date with a custom locale and add AM/PM
    return `${date.toLocaleDateString("en-GB", options)} ${hours}:${date
      .getMinutes()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 })} ${amOrPm}`;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (userIds.length > 0) {
      setFormattedRewardData((prevFormattedRewardData) => {
        if (
          prevFormattedRewardData === null ||
          prevFormattedRewardData.length === 0
        ) {
          return formatUserRewardsData(userDetailsRewardsData);
        }

        return prevFormattedRewardData;
      });
    }
  }, [userIds, userDetailsRewardsData, setFormattedRewardData]);

  const handleTitleButtonClick = (e) => {
    setTitleButtonVal(e.target.value);
  };

  //Fetch he userData collection to fetch the user emails that has support tickets
  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(radeFirestore, "userData")
      );

      const result = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Access the document ID
        data: doc.data(), // Access the document data
      }));

      // Reverse the order of the result array
      const reversedResult = result.reverse();

      const userIds = reversedResult.map((item) => item.id);
      setUserIds(userIds);
      setPZUserData(reversedResult);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPZUserData();
  }, []);
  // Function to handle the filtering of data using the email
  const filteredData = useMemo(() => {
    return PZUserdata.filter((post) => {
      const userEmail = post.id && post.id.toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();

      // Check if the user has an email and it includes the search term
      return userEmail && userEmail.includes(searchTermLower);
    }).sort((a, b) => {
      // Sort by the "User Since" timestamp in descending order
      const timestampA = a.data.userJoinedOn?.toDate().getTime() || 0;
      const timestampB = b.data.userJoinedOn?.toDate().getTime() || 0;

      return timestampB - timestampA;
    });
  }, [PZUserdata, searchTerm]);

  //Function to handle the page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //Function to switch the KYC status
  const handleClickStatusSwitch = (kycId) => {
    setSelectedKYCId(kycId);
    setModalIsOpen(true);
  };

  //Function for pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);
  const [copyStatus, setCopyStatus] = useState(
    Array(paginatedData.length).fill("Copy")
  );
  const copyToClipboard = async (id, index) => {
    try {
      await navigator.clipboard.writeText(id);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };
  if (paginatedData.length < 1) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    return (
      <>
        {selectedStakedUser ? (
          <UserStakedDetails
            user={selectedStakedUser}
            setSelectedUser={setSelectedStakedUser}
            userDetails={userDetails}
            formatTimestamp={formatTimestamp}
            formattedRewardData={formattedRewardData}
            selectedUserIndex={selectedUserIndex}
          />
        ) : selectedBorrowedUser ? (
          <UserBorrowedDetails
            user={selectedBorrowedUser}
            userDetails={userDetails}
            formatTimestamp={formatTimestamp}
            formattedRewardData={formattedRewardData}
            selectedUserIndex={selectedUserIndex}
            setSelectedBorrowedUser={setSelectedBorrowedUser}
            setSelectedUser={setSelectedStakedUser}
            borrowedUserDetails={borrowedUserDetails}
            // Add any other props needed for UserBorrowDetails
          />
        ) : (
          // Render something else or nothing
          <div>
            <div className={styles.transferOrders}>
              <div className={styles.buttonsDiv}>
                <ButtonRade
                  value="Borrowers"
                  className={styles.usdButton}
                  style={{
                    backgroundColor:
                      titleButtonVal === "Borrowers"
                        ? "#1D293A"
                        : "transparent",
                  }}
                  onClick={handleTitleButtonClick}
                >
                  Borrowers
                </ButtonRade>
                <ButtonRade
                  value="Stakers"
                  className={styles.ethButton}
                  style={{
                    backgroundColor:
                      titleButtonVal === "Stakers" ? "#1D293A" : "transparent",
                  }}
                  onClick={handleTitleButtonClick}
                >
                  Stakers
                </ButtonRade>
              </div>
              <div className={styles.refreshButtonContainer}>
                <div className={styles.searchBarDiv}>
                  <input
                    type="text"
                    className={styles.searchBar}
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <img
                    src={searchIcon}
                    className={styles.searchIcon}
                    alt="searchIcon"
                  />
                </div>
                <ButtonRade
                  customStyling={styles.refreshButton}
                  onClick={getPZUserData}
                >
                  {" "}
                  <img
                    src={refreshIcon}
                    alt="refreshIcon"
                    className={styles.refreshIcon}
                  />
                </ButtonRade>
              </div>
              <div className={styles.searchContainer}>
                <div className={styles.search}>
                  Show
                  <input
                    type="number"
                    className={styles.searchPage}
                    min={1}
                    value={pageSize}
                    onChange={(e) => setPageSize(parseInt(e.target.value))}
                  />
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={filteredData.length}
                  pageSize={pageSize}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
            <div className={styles.tableContainer}>
              {titleButtonVal !== "Borrowers" ? (
                <table className={styles.tableContainer}>
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Wallet ID</th>
                      <th>User Since</th>
                      <th>{PZ_USER_ENUM.email}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {paginatedData.map((post, index) => (
                      <tr
                        className={styles.row}
                        key={index}
                        onClick={() => handleStakeDetailsRowClick(post, index)}
                      >
                        <td>{post.data.userName || "-"}</td>
                        <td
                          className={styles.pointerHoverEffect}
                          onClick={() =>
                            copyToClipboard(
                              post.id,
                              paginatedData.indexOf(post)
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {post.id &&
                          copyStatus[paginatedData.indexOf(post)] !== "Copied!"
                            ? `${post.id.slice(0, 6)}...${post.id.slice(-4)}`
                            : copyStatus[paginatedData.indexOf(post)] !==
                                "Copied!" && "-"}
                          {copyStatus[paginatedData.indexOf(post)] ===
                            "Copied!" && (
                            <span style={{ color: "#69788c" }}>
                              {copyStatus[paginatedData.indexOf(post)]}
                            </span>
                          )}
                        </td>
                        <td>
                          {post.data.userJoinedOn
                            ? formatTimestamp(post.data.userJoinedOn)
                            : "-"}
                        </td>
                        <td>{post.data.email || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className={styles.tableContainer}>
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Wallet ID</th>
                      <th>User Since</th>
                      <th>{PZ_USER_ENUM.email}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((post, index) => (
                      <tr
                        className={styles.row}
                        key={index}
                        onClick={() => handleBorrowDetailsRowClick(post, index)}
                      >
                        <td>{post.data.userName || "-"}</td>
                        <td
                          className={styles.pointerHoverEffect}
                          onClick={() =>
                            copyToClipboard(
                              post.id,
                              paginatedData.indexOf(post)
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {post.id &&
                          copyStatus[paginatedData.indexOf(post)] !== "Copied!"
                            ? `${post.id.slice(0, 6)}...${post.id.slice(-4)}`
                            : copyStatus[paginatedData.indexOf(post)] !==
                                "Copied!" && "-"}
                          {copyStatus[paginatedData.indexOf(post)] ===
                            "Copied!" && (
                            <span style={{ color: "#69788c" }}>
                              {copyStatus[paginatedData.indexOf(post)]}
                            </span>
                          )}
                        </td>
                        <td>
                          {post.data.userJoinedOn
                            ? formatTimestamp(post.data.userJoinedOn)
                            : "-"}
                        </td>
                        <td>{post.data.email || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
};

export default RadeUsers;
