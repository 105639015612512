import { ethers } from "ethers";
import moment from "moment";

/*global BigInt */

export const formatBorrowUserDetailsData = (userDetailsData) => {
  const formattedList = [];
  const ETHER_CONVERSION = 10 ** 18;
  const weeklyPayment = 604800;
  const monthlyPayment = 2630000;
  const twoMonthsPayment = 5260000;
  const quarterlypayment = 10520000;
  const ONE_MONTH_LOAN_TERM = 2630000;
  const TWO_MONTHS_LOAN_TERM = 5260000;
  const SIX_MONTHS_LOAN_TERM = 15780000;
  const ONE_YEAR_LOAN_TERM = 31536000;
  for (const [key, value] of Object.entries(userDetailsData.userDetailsData)) {
    const obj = {};
    const length =
      userDetailsData.userDetailsData[key].userDetails.userLoans.length;

    for (let i = 0; i < length; i++) {
      obj.account = value.userDetails[0];

      obj.borrowedRUD =
        parseInt(value.userDetails[2][i][1]._hex, 16) / ETHER_CONVERSION;
      obj.loanBalance =
        parseInt(value.userDetails[2][i][2]._hex, 16) / ETHER_CONVERSION;
      obj.loanDuration = Number(value.userDetails[2][i][0]);
      obj.collateralAmount =
        parseInt(value.userDetails[2][i][0]._hex, 16) / ETHER_CONVERSION;
      obj.status = value.userDetails[2][i][3];
      obj.coll_Ratio = parseInt(value.userDetails[2][i][10]._hex, 16);
      obj.nextPayment = moment
        .unix(
          parseInt(value.userDetails[2][i][3]._hex, 16) +
            (parseInt(value.userDetails[2][i][8]._hex, 16) + 1) *
              parseInt(value.userDetails[2][i][5]._hex, 16)
        )
        .format("MM-DD-YY");
      obj.collateralAmount = parseInt(value.userDetails[2][i][0]._hex, 16);
      obj.nextPaymentAmount = parseInt(value.userDetails[2][i][7]._hex, 16);
      obj.remainingTerms =
        parseInt(value.userDetails[2][i][6]._hex, 16) -
        parseInt(value.userDetails[2][i][8]._hex, 16);
      const duration = parseInt(value.userDetails[2][i][5]._hex, 16);
      const loanDuration = parseInt(value.userDetails[2][i][4]._hex, 16);

      if (loanDuration === ONE_MONTH_LOAN_TERM) {
        obj.loanDuration = "One month";
      } else if (loanDuration === TWO_MONTHS_LOAN_TERM) {
        obj.loanDuration = "Two months";
      } else if (loanDuration === SIX_MONTHS_LOAN_TERM) {
        obj.loanDuration = "Six months";
      } else if (loanDuration === ONE_YEAR_LOAN_TERM) {
        obj.loanDuration = "One Year";
      }

      if (duration === weeklyPayment) {
        obj.paymentSchedule = "Weekly";
      } else if (duration === monthlyPayment) {
        obj.paymentSchedule = "Monthly";
      } else if (duration === twoMonthsPayment) {
        obj.paymentSchedule = "Every 2 months";
      } else if (duration === quarterlypayment) {
        obj.paymentSchedule = "Quarterly";
      }
      formattedList.push(obj);
    }
  }
  return formattedList;
};
