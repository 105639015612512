import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import RaritiUsdContract from "../../artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";
import RudStakingContract from "../../artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";
/*global BigInt */
const provider = new ethers.providers.Web3Provider(window.ethereum);

const RUDContract = new ethers.Contract(
  RaritiUsdContract.address, //RaritiUSD deployed contract address
  RaritiUsdContract.abi, //RaritiUSD deployed contract abi
  provider
);

const useRudTotalSupply = (interval = null) => {
  //state to set the total RUD supply
  const [RUDSupply, setRUDSupply] = useState(null);
  // state to store the balance of Rariti USD contract
  const [RUDInPool, setRUDInpool] = useState(null);
  //function to get the RUD balance using

  useEffect(() => {
    const getRudBalance = async () => {
      if (RUDContract) {
        const totalRUDSupply = await RUDContract.totalSupply();
        const totalRUDInPool = await RUDContract.balanceOf(
          RudStakingContract.address
        );
        setRUDInpool(totalRUDInPool);
        setRUDSupply(totalRUDSupply);
      } else {
        setRUDSupply(undefined);
        setRUDInpool(undefined);
      }
    };

    interval = setInterval(getRudBalance, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, []);
  if (RUDSupply && RUDInPool) {
    return {
      rudTotalSupply: ethers.utils.formatEther(BigInt(RUDSupply._hex)),
      totalRadeInPool: ethers.utils.formatEther(BigInt(RUDInPool._hex)),
    };
  } else {
    return {
      rudTotalSupply: null,
      totalRadeInPool: null,
    };
  }
};
export default useRudTotalSupply;
