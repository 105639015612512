import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import RudBorrowContract from "../../../artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import Spinner from "../../../components/Spinner/Spinner";
import { formatBorrowerLiquidatingNests } from "../LiquidateNestsFormat";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const provider = new ethers.providers.Web3Provider(window.ethereum);

//borrow Rud Contract
const borrowContract = new ethers.Contract(
  RudBorrowContract.address,
  RudBorrowContract.abi,
  provider
);
const UserCollateralRatio = (interval = null) => {
  const { isMobile } = useContext(AppContext);
  const [formattedData, setFormattedData] = useState(null);
  const [liquidationRatio, setliquidationRatio] = useState(null);
  const [collateralRatio, setCollateralRatio] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { tokenName } = getNetworkDetails(chainID);

        setTokenName(tokenName);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [tokenName]);

  useEffect(() => {
    const fetchData = async () => {
      if (borrowContract) {
        const latestBlock = await provider.getBlock("latest");
        const liquidatingNests = await borrowContract.getLiquidatingNests();
        setFormattedData(
          formatBorrowerLiquidatingNests(
            liquidatingNests,
            latestBlock.timestamp
          )
        );
      } else {
        console.log("loading");
      }
    };
    fetchData(); // Call the async function immediately
  }, []);

  const getCollateralRatio = async () => {
    if (borrowContract) {
      const collateralizationRatio = await borrowContract.collateralRatio();
      setCollateralRatio(collateralizationRatio);
    } else {
      setCollateralRatio(undefined);
    }
  };
  useEffect(() => {
    getCollateralRatio();
  }, []);

  const getLiquidationRatio = async () => {
    if (borrowContract) {
      const liqRatio = await borrowContract.liquidationRatio();
      setliquidationRatio(liqRatio);
    } else {
      setliquidationRatio(undefined);
    }
  };
  useEffect(() => {
    getLiquidationRatio();
  }, []);

  const CardBody = () => (
    <>
      <div className={styles.liquidationTable}>
        {formattedData.some(
          (item) =>
            item.collRatio < Number(collateralRatio).toString() &&
            item.collRatio > 0
        ) ? (
          <table className={styles.tableHeading}>
            <thead>
              <tr
                className={cx(styles.borrowTableHeading, {
                  [styles.borrowTableHeadingWeb]: !isMobile,
                  [styles.borrowTableHeadingMob]: isMobile,
                })}
              >
                <th>{LIQUIDATE_ENUM.userName}</th>
                <th>{LIQUIDATE_ENUM.borrowAndColl}</th>
                <th>{LIQUIDATE_ENUM.collateralRatio}</th>
                <th>{LIQUIDATE_ENUM.revitalizeFactor}</th>
                <th>{LIQUIDATE_ENUM.liquidationAmount}</th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((item, index) => (
                <tr className={styles.borrowTableNest1}>
                  <td>{item.user}</td>
                  <td>
                    {Number(item.borrowedRUD).toFixed(MAX_DECIMAL_PLACE)}/
                    {Number(item.collateralAmount / ETHER_CONVERSION).toFixed(
                      MAX_DECIMAL_PLACE
                    )}
                  </td>
                  <td
                    className={cx(styles.collateralRatio, {
                      [styles.mediumHealth]:
                        item.collRatio > Number(liquidationRatio).toString(),
                      [styles.riskyCollateral]:
                        item.collRatio < Number(liquidationRatio).toString(),
                    })}
                  >
                    {item.collRatio}%
                  </td>
                  <td>{Number(collateralRatio).toString() - item.collRatio}</td>
                  <td>
                    {Number(item.liquidationAmount / ETHER_CONVERSION).toFixed(
                      MAX_DECIMAL_PLACE
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.liquidationRatioContent}>
            {LIQUIDATE_ENUM.liquidationRatio}
          </div>
        )}
      </div>
    </>
  );
  if (!formattedData || !tokenName) {
    return <Spinner />;
  }
  return (
    <div
      className={cx(styles.returnUsermainDiv, {
        [styles.returnUsermainDivWeb]: !isMobile,
        [styles.returnUsermainDivMob]: isMobile,
      })}
    >
      <CardBody />
    </div>
  );
};

export default UserCollateralRatio;
