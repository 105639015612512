import { ethers } from "ethers";
import { useState, useEffect } from "react";
import RUDContract from "../../artifacts/Contracts/stablecoin/VestingToken.sol/RADE.json";

const provider = new ethers.providers.Web3Provider(window.ethereum);

const RUDContractInstance = new ethers.Contract(
  RUDContract.address, //RaritiUSD deployed contract address
  RUDContract.abi, //RaritiUSD deployed contract abi
  provider
);

let interval;

const useRADEInWallet = (accounts, intervalTime = 1000) => {
  const [userDetailsRewardsData, setUserDetailsRewardsData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getRudBalance = async () => {
      if (RUDContractInstance && accounts.length > 0) {
        const updatedUserDetailsData = {};

        for (const account of accounts) {
          if (account) {
            const accountRUDBalance = await RUDContractInstance.balanceOf(
              account
            );
            const vestedUserDetail = await RUDContractInstance.getUserDetails(
              account
            );

            updatedUserDetailsData[account] = {
              radeBalance: accountRUDBalance
                ? ethers.BigNumber.from(accountRUDBalance._hex)
                : null,
              userVestedAmount: vestedUserDetail
                ? ethers.utils.formatEther(
                    ethers.BigNumber.from(vestedUserDetail[1]._hex)
                  )
                : null,
              userVestedDetails: vestedUserDetail ? vestedUserDetail : null,
            };
          }
        }

        setUserDetailsRewardsData(updatedUserDetailsData);
        setLoading(false);
      }
    };

    if (accounts.length > 0) {
      clearInterval(interval);
      interval = setInterval(getRudBalance, intervalTime);
    }

    return () => {
      clearInterval(interval);
    };
  }, [accounts]);

  return {
    userDetailsRewardsData: userDetailsRewardsData,
    loading,
  };
};

export default useRADEInWallet;
