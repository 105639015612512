export const formatUserDetailsData = (userDetailsData) => {
  console.log("userDetailsData", userDetailsData);
  const formattedList = [];

  for (const [key, value] of Object.entries(userDetailsData.userDetailsData)) {
    const length =
      userDetailsData.userDetailsData[key].userDetails.userStakes.length;
    console.log("length", length);
    for (let i = 0; i < length; i++) {
      const obj = {};
      obj.account = value.userDetails[0];
      obj.stakedRUD = parseFloat(value.userDetails[2][i][0]) / 10 ** 18;
      obj.lockDuration = Number(value.userDetails[2][i][2]);
      obj.maturityDate = Number(value.userDetails[2][i][3]);
      obj.stakedTime = value.userDetails[2][i][1];
      obj.maturityDateInSeconds = value.userDetails[2][i][3];
      obj.status = value.userDetails[2][i][4];
      formattedList.push(obj);
    }
    console.log("formatted list", formattedList);
  }
  return formattedList;
};
