export const calculateTotalVestedAmount = (userDetailsData) => {
  let totalVestedAmount = 0; // Initialize total vested amount

  for (const [key, value] of Object.entries(
    userDetailsData.userDetailsRewardsData
  )) {
    const length = value.userVestedDetails.length;

    if (
      value.userVestedDetails &&
      value.userVestedDetails.userVests &&
      value.userVestedDetails.userVests.length >= 0
    ) {
      for (let i = 0; i < length; i++) {
        if (value.userVestedDetails.userVests[i]) {
          // Accumulate vested amount for each user
          totalVestedAmount += parseFloat(value.userVestedAmount); // Assuming userVestedAmount is a number
        }
      }
    }
  }

  return totalVestedAmount.toString();
};
