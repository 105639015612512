import React, { useState } from "react";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import Radio from "../../components/RadioButtons/RadioButton";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import SelectBoxWOSearch from "../../components/SelectBoxWOSearch/SelectBoxWOSearch";
const KYCStatusSwitchPopUp = ({
  setModalIsOpen,
  handleKYCStatusUpdate,
  setKYCRejectionReason,
  KYCRejectionReason,
}) => {
  const [statusSwitchInput, setStatusSwitchInput] = useState(""); //state for storing the status input
  const [showRejectReason, setShowRejectReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  // Function to handle the status switching of the transfer
  const handleClickStatusSwitch = (e) => {
    setStatusSwitchInput(e.target.value);
    if (e.target.value === "Rejected") {
      setShowRejectReason(true);
    } else {
      setShowRejectReason(false);
    }
  };
  const handleChangeCountryButtonSelection = (reason) => {
    setSelectedReason(reason);
  };
  //select box options for country
  const countryOptions = [
    // North America
    { value: "Reason 1", label: "Reason 1" },
    { value: "Reason 2", label: "Reason 2" },
    { value: "Reason 3", label: "Reason 3" },
    // Add more North American countries as needed
  ];
  const dismissError = () => {};
  // Function to handle cancel button
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };

  const handleClickUpdateButton = () => {
    setModalIsOpen(false);
    handleKYCStatusUpdate(statusSwitchInput); // Call the handleKYCStatusUpdate function with the new status
  };

  // Radio button options
  const statusSwitchOptions = [
    { value: "Verified", label: "Verified" },
    { value: "Rejected", label: "Rejected" },
  ];
  const handleInputChange = (event) => {
    setKYCRejectionReason(event.target.value);
  };
  return (
    <div>
      <div className={styles.changeStatus}>{PZ_TRANSFER_ENUM.changeStatus}</div>
      <div className={styles.radioButtonContainer}>
        <Radio
          name="statusSwitchOptions"
          value={statusSwitchInput}
          options={statusSwitchOptions}
          onChangeFunc={handleClickStatusSwitch}
        />
        <div className={styles.showRejectReason}>
          {showRejectReason && (
            <input
              className={styles.inputBox}
              type="text"
              value={KYCRejectionReason}
              onChange={handleInputChange}
              placeholder="Enter your rejection reason"
            />
            // <SelectBoxWOSearch
            //   placeHolder="Select Rejection Reason"
            //   options={countryOptions}
            //   setSelectedValue={setSelectedReason}
            //   selectedValue={selectedReason}
            //   onChange={handleChangeCountryButtonSelection}
            //   errorDismissOnclick={dismissError}
            // />
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonRade
          customStyling={styles.cancelButton}
          onClick={handleClickCancelButton}
        >
          {PZ_TRANSFER_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          customStyling={styles.updateButton}
          onClick={handleClickUpdateButton}
        >
          {PZ_TRANSFER_ENUM.updateButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default KYCStatusSwitchPopUp;
