export const ADMIN_ENUM = {
  protocol: "Protocol",
  userStats: "Users",
  nests: "Nests",
  staking: "Staking",
  liquidation: "Liquidation",
  kyc: "KYC",
  vouchVault: "VouchVault",
  tvl: "Total Value Locked",
  chain: "Chain",
  ethereumChain: "Ethereum",
  usdButton: "USD",
  ethButton: "ETH",
  volume: "Volume",
  marketCap: "Market Cap",
  totalNests: "Total Nests",
  collateralRatio: "Coll.Ratio",
  rudInWallet: "Your RUD Balance",
  ethInWallet: "Wallet ETH Balance",
  contractDAIBalance: "Contract DAI Balance",
  contractEthBalance: "Contract ETH Balance",
  contractRudBalance: "Contract RUD Balance",
  totalActive: "Total Active",
  totalClosed: "Total Closed",
  totalDefaults: "Total Defaults",
  tokenStats: "Token Stats",
  rudSupply: "$RUD in Supply",
  CDPStats: "CDP Stats",
  stakedRUD: "Staked $RUD",
  vestedTokens: "Vested Tokens(RADE)",
  unvestedTokens: "Unvested Tokens(RADE)",
  pledgedETH: "ETH Pledged ",
  borrowersButton: "Borrowers",
  stakersButton: "Stakers",
  userName: "Username",
  email: "Email",
  borrowersNests: "#Nests",
  totalPledged: "Total Pledged",
  totalBorrowed: "Total Borrowed",
  outstanding: "Outstanding",
  stakersAccounts: "#Accounts",
  totalStaked: "Total Staked",
  totalRewards: "Total Rewards",
  cdpId: "CDP ID",
  pledged: "Pledged ETH",
  borrowedRUD: "Borrowed $RUD",
  loanBalance: "LoanBalance",
  monthly: "Monthly",
  payment: "Payment Term",
  vspId: "VSP ID",
  duration: "Duration",
  rewards: "Rewards",
  maturityDate: "Maturity Date",
  status: "Status",
  defaults: "Defaults",
  ethPriceFeedAddress: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e", // Goerli address
};
