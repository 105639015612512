import React, { useState } from "react";
import styles from "./index.module.scss";
import { ADMIN_ENUM } from "../../enums/adminEnum";
import { Button } from "../../components";
import BorrowersPaymentDefault from "./PaymentDefault/PaymentDefault";
import UserCollateralRatio from "./UserCollateralRatio/UserCollateralRatio";
const LiquidationPanel = () => {
  const [titleButtonVal, setTitleButtonVal] = useState("DEFAULTS");

  const handleTitleButtonClick = (e) => {
    setTitleButtonVal(e.target.value);
  };

  const TitleButtons = () => (
    <div className={styles.liquidationPanelHeader}>
      <div className={styles.buttonsDiv}>
        <Button
          value="DEFAULTS"
          className={styles.defaultsButton}
          style={{
            backgroundColor:
              titleButtonVal === "DEFAULTS" ? "#1D293A" : "transparent",
          }}
          onClick={handleTitleButtonClick}
        >
          {ADMIN_ENUM.defaults}
        </Button>
        <Button
          value="COLL_RATIO"
          className={styles.collButton}
          style={{
            backgroundColor:
              titleButtonVal === "COLL_RATIO" ? "#1D293A" : "transparent",
          }}
          onClick={handleTitleButtonClick}
        >
          {ADMIN_ENUM.collateralRatio}
        </Button>
      </div>
    </div>
  );
  return (
    <div className={styles.liquidationPanelMainDiv}>
      <TitleButtons />
      {titleButtonVal === "DEFAULTS" && <BorrowersPaymentDefault />}
      {titleButtonVal === "COLL_RATIO" && <UserCollateralRatio />}
    </div>
  );
};
export default LiquidationPanel;
