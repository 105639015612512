export const HOME_ENUM = {
  en: {
    logo: "RADE",
    title: "Welcome.",
    subheading: "What would you like to use RADE for?",
    button1: "Borrow at 0% Interest",
    button2: "Be a Liquidity Providor and Earn",
    button3: "Buy RUD to get started",
    table_heading: "Protocol Stats",
    content1: "Borrowing Fee",
    dashboard: "Dashboard",
    symbol: ">",
    home: "Home",
    borrow_key: "Borrowing Fee",
    TLV_key: "Total Value Locked [TVL]",
    nests_key: "Nests",
    supply_key: "RUD Supply",
    pool_key: "RUD in RADE Pool",
    stake_key: "Staked RUD",
    collateral_ratio: "Total Collateral Ratio",
    borrow_val: "0.25%",
    TLV_val: "214 ETH [$1.14M]",
    nests_val: "884",
    supply_val: "394M",
    pool_val: "312M [68.5%]",
    stake_val: "18M",
    ratio_val: "212.5%",
    token: "Token",
    update: "Updated",
    change: "Change",
    price: "Price",
    token_value: "Ethereum",
    tokenv_mob: "ETH",
    update_value: "1 minute ago",
    change_value: "2.64%",
    price_value: "$3,229",
    stake_button: "staking",
    borrow_button: "Borrowing",
    totalStakedRUD: "Your Total Staked RUD",
    currentAPY: "Current APY",
    totalBorrowedRUD: "Your Total Borrowed RUD",
    paymentSchedule: "Payment schedule",
    nextTermPayment: "Next term payment",
    collateralizationRatio: "Collateralization Ratio",
    switchNetworkButton: "Switch network",
    errorMessageToSwitchNetwork:
      "This network is not supported by RADE DApp at this time. Please switch to ethereum mainnet on your wallet to avail services.",
    userName: "User_Name",
    fullName: "Full Name",
    userDeniedTransaction: "User Denied the Transaction signature!",
    returnAfterRudBuy: "RUD Balance",
    rudBuyReturnUserSubHeading: "What would you like to use RUD for?",
    userDeniedTokenAdding: "User Denied to add token to wallet asset !",
    portfolio: "My Portfolio",
    cancel: "Cancel",
    continue: "Continue",
    logoutMessage: "Are you sure you want to Logout? ",
  },
};
export const TOOL_TIP = {
  totalValueLocked:
    "Total Value Locked [TVL] is the total value of all assets locked in RADE Protocol. TVL includes all the coins deposited in all the functions that RADE protocol offers, including staking and liquidity pool.",
  nests:
    "Total number of nests opened by users in the protocol. Each Nest represent an active loan account.",
  RUDSupply:
    "Total number of $RUD minted by RADE protocol or current supply of $RUD held by RADE users.",
  RUDInPool: "Total number of $RUD staked in the Liquidity Pool.",
  totalCollatRatio:
    "Total collateral ratio of all active nests(loan accounts) in the protocol.",
  cryptoCollat: "Collateralize your loan by pledging ETH ",
  membervouch:
    "Collateralize your loan by asking a confidant to pledge for you. Please read docs for more info",
  kycNotCompleted: "KYC NOT verified",
  disconnect: "Disconnect",
  clickToChangeAvatar: "click to change",
  paymentTerms:
    "Payment term represents the frequency at you payback a part of your total borrowed amount.",
  borrowAmount: "Total borrowed amount in $RUD",
  maxBorrowAmount:
    "Maximum allowed $RUD amount that can be borrowed against the pledged collateral.",
  borrowingFee:
    "A one time borrowing fee is charged to issue a collateralized loan. Borrowing fee is based on the amount of the loan, loan term and the payment term chosen by the user. ",
  collatRatio:
    "A collateral ratio is the ratio between the Dollar value of the collateral (ETH) in your position and its debt in $RUD. The collateral ratio of your loan account (called 'Nest') will fluctuate over time as the price of ETH changes.",
  radePool:
    "Liqudiity Pool maintained by RADE protocol to faciliate staking and borrowing",
  currentPriceInRUD: "Current price of Ethereum expressed in $RUD",
  slippageTolerance:
    "Slippage tolerance is a setting for the amount of price slippage you are willing to accept for a trade. By setting slippage tolerance, you basically setting a minimum amount on how many tokens you will accept, in the event that the price increases or decreases.",

  minimumReceived:
    "Gauranteed minimum value of $RUD that will be recieved after deducting the slippage tolerance and rade fees.",
  RADEFees:
    "A one time borrowing fee is charged to issue a collateralized loan. Borrowing fee is based on the amount of the loan, loan term and the payment term chosen by the user. ",
  comingSoon: "Coming soon...",
  onHoverMintButton: "Reward can only be claimed after vesting period",
};
