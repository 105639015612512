import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { UserContext } from "./context";
import { useMediaQuery } from "react-responsive";
import RadeUser from "./pages/RadeUsers/RadeUsers";
import LoginWithGoogle from "./pages/LoginWithGoogle/LoginWithGoogle";
import { AppContext } from "./context";
import Header from "./components/header/header";
import { NavLink, useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Spinner from "./components/Spinner/Spinner";
import styles from "./styles/app.module.scss";
import RadeUserKYC from "./pages/RadeUserKYC/RadeUserKYC";
import Nests from "./pages/Nests/Nests";
import Protocol from "./pages/ProtocolPanel/ProtocolPanel";
import LiquidationPanel from "./pages/Liquidate/Liquidate";
import { useEthers } from "@usedapp/core";
import useRudInWallet from "./Hooks/useRudBalance/useRudBalance";
import useWalletEthBalance from "./Hooks/useWalletEthBalance/useWalletEthBalance";
import useRudBalanceInContract from "./Hooks/useContractRUDBalance/useContractRUDBalance";
import StakedUserDetails from "./pages/userStakeDetails/UserStakeDetails";
function App() {
  const [loading, setLoading] = useState(false); // Loading state
  const [sendLoginPageNumber, setSendLoginPageNumber] = useState(false); // State variables for storing google login page number
  const [radexFlow, setRadexFlow] = useState(null); //state that represent the radex flow
  const [onClickLoginButton, setOnClickLoginButton] = useState(false);
  const [profileEmail, setProfileEmail] = useState(false); // State for profile email
  const [profile, setProfile] = useState([]); // State for profile information
  const location = useLocation(); //variable for detecting the location of the app
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" }); // state that defines the mobile view
  const { account } = useEthers();
  const { rudBalance, rudTotalSupply, totalRadeInPool } =
    useRudInWallet(account);

  // const { radeBalance } = useRadeInWallet(account);
  const { ethBalanceInWallet } = useWalletEthBalance(account);
  const { contractRudBalance } = useRudBalanceInContract();

  //persisting the state using firabese onAuthStateChanged method
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // if connected email is team@rariti.io
      if (user.email === "team@rariti.io") {
        setProfileEmail(user.email);
      } else {
        setProfileEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);
  //Function for Radex app google login flow
  const radexAppLoginFlow = () => {
    switch (sendLoginPageNumber) {
      case 0:
        return (
          <LoginWithGoogle
            profileEmail={profileEmail}
            setProfileEmail={setProfileEmail}
            profile={profile}
            setProfile={setProfile}
            onClickLoginButton={setOnClickLoginButton}
            setOnClickLoginButton={setOnClickLoginButton}
            radexFlow={radexFlow}
            setRadexFlow={setRadexFlow}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      <AppContext.Provider
        value={{
          isMobile,
          radexFlow,
          setRadexFlow,
          sendLoginPageNumber,
          setSendLoginPageNumber,
          setOnClickLoginButton,
          loading,
          setLoading,
          profileEmail,
        }}
      >
        <UserContext.Provider
          value={{
            account,
            rudBalance,
            rudTotalSupply,
            contractRudBalance,

            ethBalanceInWallet,
            totalRadeInPool,
          }}
        >
          <Header
            profileEmail={profileEmail}
            setProfileEmail={setProfileEmail}
            profile={profile}
            setProfile={setProfile}
            setSendLoginPageNumber={setSendLoginPageNumber}
            sendLoginPageNumber={sendLoginPageNumber}
            onClickLoginButton={onClickLoginButton}
            setOnClickLoginButton={setOnClickLoginButton}
          />
          {loading && <Spinner />}
          {!loading && (
            <div className={styles.spinnerDiv}>{radexAppLoginFlow()}</div>
          )}

          <div>
            {!isMobile && !onClickLoginButton && (
              <nav className={styles.navBar}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        className={`${styles.navLink} ${
                          location.pathname === "/RadeUsers"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/RadeUsers"
                      >
                        Rade Users
                      </NavLink>
                    </li>
                  )}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        className={`${styles.navLink} ${
                          location.pathname === "/RadeUsersKYC"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/RadeUsersKYC"
                      >
                        KYC
                      </NavLink>
                    </li>
                  )}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        className={`${styles.navLink} ${
                          location.pathname === "/admin_protocol"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/admin_protocol"
                      >
                        Protocol
                      </NavLink>
                    </li>
                  )}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        className={`${styles.navLink} ${
                          location.pathname === "/liquidation"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/liquidation"
                      >
                        Liquidation
                      </NavLink>
                    </li>
                  )}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        className={`${styles.navLink} ${
                          location.pathname === "/staking"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/staking"
                      >
                        Staking
                      </NavLink>
                    </li>
                  )}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        className={`${styles.navLink} ${
                          location.pathname === "/nests"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/nests"
                      >
                        Nests
                      </NavLink>
                    </li>
                  )}
                </ul>
              </nav>
            )}

            {!onClickLoginButton && profileEmail && (
              <div className={styles.routesContainer}>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/RadeUsers" replace />}
                  />
                  <Route path="/RadeUsers" element={<RadeUser />} />
                  <Route path="/RadeUsersKYC" element={<RadeUserKYC />} />
                  <Route path="/admin_protocol" element={<Protocol />} />
                  <Route path="/liquidation" element={<LiquidationPanel />} />
                  <Route path="/staking" element={<StakedUserDetails />} />
                  <Route path="/nests" element={<Nests />} />
                </Routes>
              </div>
            )}
          </div>
        </UserContext.Provider>
      </AppContext.Provider>
    </>
  );
}

export default App;
