import React from "react";
import { ethers } from "ethers";
import styles from "../RadeUserKYC/index.module.scss";
const UserBorrowedDetails = ({
  user,
  isVisible,
  setSelectedUser,
  userDetails,
  formatTimestamp,
  formattedRewardData,
  selectedUserIndex,
  setSelectedBorrowedUser,
  borrowedUserDetails,
}) => {
  /*global BigInt */

  const handleBackButtonClick = () => {
    setSelectedBorrowedUser(null);
  };
  // Display only specific details if isVisible is false
  if (!isVisible) {
    return (
      <div>
        <div className={styles.userDetails}>
          <div>User Details:</div>
          <button className={styles.backButton} onClick={handleBackButtonClick}>
            Back
          </button>
        </div>

        <div className={styles.submittedKYCDetails}>
          <div className={styles.kycDetails}>
            {" "}
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>User Name:</div>
              <div className={styles.kycValue}>
                {user.data.userName ? user.data.userName : "-"}
              </div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Email:</div>
              <div className={styles.kycValue}>
                {user.data.email ? user.data.email : "-"}
              </div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Outstanding RUD:</div>
              <div className={styles.kycValue}>
                {borrowedUserDetails?.userDetailsData[user.id]?.userDetails
                  ?.userLoans[0]
                  ? ethers.utils.formatEther(
                      BigInt(
                        borrowedUserDetails.userDetailsData[user.id].userDetails
                          .userLoans[0].loanBalance
                      )
                    )
                  : "-"}
              </div>
            </div>
          </div>
          <div className={styles.kycDetails}>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Wallet ID:</div>
              <div className={styles.kycValue}>{user.id ? user.id : "-"}</div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>User Since:</div>
              <div className={styles.kycValue}>
                {user.data.userJoinedOn
                  ? formatTimestamp(user.data.userJoinedOn)
                  : "-"}
              </div>
            </div>
          </div>

          <div className={styles.kycDetails}>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>#Nests [Borrow]:</div>
              <div className={styles.kycValue}>
                {borrowedUserDetails?.userDetailsData[user.id]
                  ? borrowedUserDetails.userDetailsData[user.id].userDetails
                      .userLoans.length
                  : "-"}
              </div>
            </div>

            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Borrowed RUD:</div>
              <div className={styles.kycValue}>
                {" "}
                {borrowedUserDetails?.userDetailsData[user.id]
                  ? ethers.utils.formatEther(
                      BigInt(
                        borrowedUserDetails.userDetailsData[user.id].userDetails
                          .totalLoanAmount
                      )
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserBorrowedDetails;
