import { ethers } from "ethers";
import { useState, useEffect } from "react";
import BorrowingContract from "../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
/*global BigInt */
const provider = new ethers.providers.Web3Provider(window.ethereum);

const RudBorrowContract = new ethers.Contract(
  BorrowingContract.address, // RudBorrowing deployed contract address
  BorrowingContract.abi, // RudBorrowing deployed contract abi
  provider
);

const useBorrowedUserDetails = (accounts, interval = null) => {
  // State to set the connected users' borrowed details
  const [userBorrowedDetails, setUserBorrowedDetails] = useState({});
  // State to track loading status
  const [loadingBorrowDetails, setLoadingBorrowDetails] = useState(true);

  useEffect(() => {
    const getBorrowedUserDetails = async () => {
      if (RudBorrowContract) {
        const userDetails = {};
        const totalBorrowedRUD = await RudBorrowContract.getLoanBook();
        const balance = await provider.getBalance(BorrowingContract.address);

        for (const account of accounts) {
          if (account) {
            const borrowedUserDetails =
              await RudBorrowContract.getBorrowedUserDetails(account);
            userDetails[account] = {
              userDetails: borrowedUserDetails,
              borrowedRud: ethers.utils.formatEther(
                borrowedUserDetails.totalLoanAmount
              ),
              totalRUDBorrowed: ethers.utils.formatEther(
                BigInt(totalBorrowedRUD._hex)
              ),
              contractBorrowBalance: ethers.utils.formatEther(balance),
            };
          }
        }

        setUserBorrowedDetails(userDetails);
        setLoadingBorrowDetails(false);
      }
    };

    clearInterval(interval);
    interval = setInterval(getBorrowedUserDetails, 1000);

    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [accounts]);

  return {
    userDetailsData: userBorrowedDetails,
    loadingBorrowDetails: loadingBorrowDetails,
  };
};

export default useBorrowedUserDetails;
