import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";

const provider = new ethers.providers.Web3Provider(window.ethereum);
let currAcc = null;
const useWalletEthBalance = (account, interval = null) => {
  const [walletEthBalance, setWalletEthBalance] = useState(0);

  useEffect(() => {
    const getEthBalance = () => {
      if (account) {
        provider.getBalance(account).then((balance) => {
          const balanceInEth = ethers.utils.formatEther(balance);
          setWalletEthBalance(balanceInEth);
        });
      } else {
        setWalletEthBalance(null);
      }
    };
    if (account !== currAcc) {
      clearInterval(interval);
      currAcc = account;
    }
    interval = setInterval(getEthBalance, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAcc = null;
    };
  }, [account]);
  if (walletEthBalance) {
    return {
      ethBalanceInWallet: walletEthBalance,
      loading: false,
    };
  } else {
    return {
      ethBalanceInWallet: null,
      loading: true,
    };
  }
};
export default useWalletEthBalance;
