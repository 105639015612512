export const USER_DATA_USD = [
  {
    id: 1,
    year: 2023,
    profit: 80000,
  },
  {
    id: 2,
    year: 2024,
    profit: 50000,
  },
  {
    id: 3,
    year: 2025,
    profit: 10000,
  },
  {
    id: 4,
    year: 2026,
    profit: 25000,
  },
];
export const USER_DATA_ETH = [
  {
    id: 1,
    year: 2024,
    profit: 10000,
  },
  {
    id: 2,
    year: 2025,
    profit: 40000,
  },
  {
    id: 3,
    year: 2026,
    profit: 10000,
  },
  {
    id: 4,
    year: 2027,
    profit: 85000,
  },
  {
    id: 4,
    year: 2028,
    profit: 50000,
  },
  {
    id: 4,
    year: 2029,
    profit: 45000,
  },
];
