import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import BorrowingContract from "../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
/*global BigInt */
const provider = new ethers.providers.Web3Provider(window.ethereum);

const RudBorrowContract = new ethers.Contract(
  BorrowingContract.address, //RudBorrowing deployed contract address
  BorrowingContract.abi, //RudBorrowing deployed contract abi
  provider
);

const useTotalBorrowedDetails = (interval = null) => {
  // state to set the total borrowed RUD
  const [borrowedRUDInTotal, setBorrowedRUDInTotal] = useState(null);
  // state to set the contract balance of borrow contract
  const [borrowContractBalance, setBorrowContractBalance] = useState(null);
  // state that store total nests
  const [nest, setNests] = useState(null);
  // closed nests
  const [closedNests, setClosedNests] = useState(null);
  //function to get the RUD balance using
  useEffect(() => {
    const getBorrowedUserDetails = async () => {
      if (RudBorrowContract) {
        const totalBorrowedRUD = await RudBorrowContract.getLoanBook();
        provider.getBalance(BorrowingContract.address).then((balance) => {
          setBorrowContractBalance(ethers.utils.formatEther(balance));
        });
        const totalNests = await RudBorrowContract.getTotalNumNests();
        const totalNestsCount = totalNests[0];
        const totalClosedNestCount = totalNests[1];
        setNests(totalNestsCount);
        setBorrowedRUDInTotal(totalBorrowedRUD);
        setClosedNests(totalClosedNestCount);
      } else {
        setBorrowedRUDInTotal(undefined);
        setNests(null);
        setClosedNests(null);
        setBorrowContractBalance(undefined);
      }
    };

    interval = setInterval(getBorrowedUserDetails, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, []);
  if (borrowedRUDInTotal && borrowContractBalance && nest) {
    return {
      totalRUDBorrowed: ethers.utils.formatEther(
        BigInt(borrowedRUDInTotal._hex)
      ),
      contractBorrowBalance: borrowContractBalance,
      totalNumOfNests: nest,
      closedNests: closedNests,
      loading: false,
    };
  } else {
    return {
      totalRUDBorrowed: null,
      contractBorrowBalance: null,
      totalNumOfNests: null,
      closedNests: null,
      loading: true,
    };
  }
};
export default useTotalBorrowedDetails;
