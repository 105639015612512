export const formatBorrowerLiquidatingNests = (data, timestamp) => {
  const formattedList = [];
  for (let i = 0; i < data.length; i++) {
    const obj = {};
    const CONVERSION_FACTOR = 10 ** 18;
    let paymentsMissed = 0;
    obj.user = "UNU";
    obj.account = data[i][0];
    obj.userIndex = parseInt(data[i][1]._hex, 16);
    for (let j = 0; j < data[i][3].length; j++) {
      obj.borrowedRUD = parseInt(data[i][3][j][1]._hex, 16) / CONVERSION_FACTOR;
      obj.loanBalance = parseInt(data[i][3][j][2]._hex, 16) / CONVERSION_FACTOR;
      obj.collRatio = parseInt(data[i][3][j][10]._hex, 16);
      console.log("obj.collRatio", obj.collRatio);
      obj.collateralAmount = parseInt(data[i][3][j][0]._hex, 16);
      const paymentDuration = parseInt(data[i][3][j][5]._hex, 16);
      const loanStartDate = parseInt(data[i][3][j][3]._hex, 16);
      const loanEndDate = parseInt(data[i][3][j][11]._hex, 16);
      const numberOfPayments = parseInt(data[i][3][j][6]._hex, 16);
      const paymentsMade = parseInt(data[i][3][j][8]._hex, 16);
      const minimumRepayment =
        parseInt(data[i][3][j][7]._hex, 16) / CONVERSION_FACTOR;
      const currentTime = Math.floor(timestamp);
      const paymentsDueTillDate = Math.floor(
        (currentTime - loanStartDate) / paymentDuration
      );
      if (currentTime > loanEndDate) {
        paymentsMissed = numberOfPayments - paymentsMade;
      } else {
        paymentsMissed = paymentsDueTillDate - paymentsMade;
      }
      obj.defaultValue = paymentsMissed * minimumRepayment;
      obj.liquidateColl =
        (((obj.defaultValue * CONVERSION_FACTOR) / obj.borrowedRUD) *
          obj.collateralAmount) /
        CONVERSION_FACTOR;
      const proportionalAsset =
        (minimumRepayment * CONVERSION_FACTOR) / obj.borrowedRUD;
      obj.liquidationAmount =
        (proportionalAsset * obj.collateralAmount) / CONVERSION_FACTOR;
      formattedList.push(obj);
    }
  }
  return formattedList;
};
