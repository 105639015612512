export const APP_ENUM = {
  ethPriceFeedAddressGoerli: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e", // Goerli address
  ethPriceFeedAddressMainnet: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419", // Mainnet address
  ethPriceFeedAddressMumbai: "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
  ethNetwork: "Ethereum Main Network",
  goerliTestNetwork: "Goerli Test Network",
  hardhatNetwork: "Hardhat",
  sepoliaNetwork: "Sepolia Test Network",
  mumbaiNetwork: "Mumbai",
  eth: "ETH",
  goerliEth: "GETH",
  mumbaiToken: "MATIC",
};
