import React from "react";
import styles from "../RadeUserKYC/index.module.scss";
const UserStakedDetails = ({
  user,
  isVisible,
  setSelectedUser,
  userDetails,
  formatTimestamp,
  formattedRewardData,
  selectedUserIndex,
  setSelectedBorrowedUser,
}) => {
  const handleBackButtonClick = () => {
    setSelectedUser(null);
  };
  // Display only specific details if isVisible is false
  if (!isVisible) {
    return (
      <div>
        <div className={styles.userDetails}>
          <div>User Details:</div>
          <button className={styles.backButton} onClick={handleBackButtonClick}>
            Back
          </button>
        </div>

        <div className={styles.submittedKYCDetails}>
          <div className={styles.kycDetails}>
            {" "}
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>User Name:</div>
              <div className={styles.kycValue}>
                {user.data.userName ? user.data.userName : "-"}
              </div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Email:</div>
              <div className={styles.kycValue}>
                {user.data.email ? user.data.email : "-"}
              </div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Total Rewards [RUD]:</div>
              <div className={styles.kycValue}>
                {" "}
                {formattedRewardData &&
                formattedRewardData[selectedUserIndex] &&
                formattedRewardData[selectedUserIndex].userVestAmount &&
                formattedRewardData[selectedUserIndex].account === user.id
                  ? formattedRewardData[
                      selectedUserIndex
                    ].userVestAmount.toFixed(2)
                  : "-"}
              </div>
            </div>
          </div>
          <div className={styles.kycDetails}>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Wallet ID:</div>
              <div className={styles.kycValue}>{user.id ? user.id : "-"}</div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>User Since:</div>
              <div className={styles.kycValue}>
                {user.data.userJoinedOn
                  ? formatTimestamp(user.data.userJoinedOn)
                  : "-"}
              </div>
            </div>
          </div>

          <div className={styles.kycDetails}>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>#Accounts [Stake]:</div>
              <div className={styles.kycValue}>
                {userDetails?.userDetailsData[user.id]
                  ? Number(
                      userDetails.userDetailsData[user.id].userDetails
                        .userStakes.length
                    )
                  : "-"}
              </div>
            </div>
            <div className={styles.kycDetail}>
              <div className={styles.kycHeading}>Staked RUD:</div>
              <div className={styles.kycValue}>
                {" "}
                {userDetails?.userDetailsData[user.id]
                  ? userDetails.userDetailsData[user.id].stakedRudBalance
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserStakedDetails;
