import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Button, RadeTitle } from "../../components";
import Logo from "../../assets/radexIcon.svg";
import { HOME_ENUM } from "../../enums/homeEnum";
import { radeFirestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { ADMIN_ENUM } from "../../enums/adminEnum";
import LineChart from "../../components/LineChart/LineChart";
import { USER_DATA_USD } from "./UserData";
import Spinner from "../../components/Spinner/Spinner";
import etherLogo from "../../assets/etherLogo.svg";
import { USER_DATA_ETH } from "./UserData";
import useETHPrice from "../../Hooks/usePrice/usePrice";
import useRudBalanceInContract from "../../Hooks/useContractRUDBalance/useContractRUDBalance";
import useRudTotalSupply from "../../Hooks/useRudTotalSupply/useRudTotalSupply";
import useTotalBorrowedDetails from "../../Hooks/useTotalBorrowedDetails/useTotalborrowedDetails";
import getNetworkDetails from "../../components/NetworkDetails/NetworkDetails";
import useRADEInWallet from "../../Hooks/useRADEVestDetails/useRADEVestDetails";
import { calculateTotalVestedAmount } from "../../Hooks/useTotalVestedDetails/useTotalVestedDetails";
/* global BigInt */
let currAddress = null;
const MAX_DECIMAL_PLACE = 2;
const Protocol = (interval = null) => {
  const { rudTotalSupply, totalRadeInPool } = useRudTotalSupply();
  const [userIds, setUserIds] = useState([]); // state to store the user IDs

  const userDetailsRewardsData = useRADEInWallet(userIds);
  const totalVestedAmount = calculateTotalVestedAmount(userDetailsRewardsData);
  const { contractRudBalance } = useRudBalanceInContract();
  const {
    contractBorrowBalance,
    totalRUDBorrowed,
    totalNumOfNests,
    closedNests,
  } = useTotalBorrowedDetails();
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  //timer to check each 10sec on which metamask network is connected
  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(radeFirestore, "userData")
      );

      const result = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Access the document ID
        data: doc.data(), // Access the document data
      }));

      // Reverse the order of the result array
      const reversedResult = result.reverse();

      const userIds = reversedResult.map((item) => item.id);
      setUserIds(userIds);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPZUserData();
  }, []);
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { priceFeedAddress } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress]);
  const { ethPrice, loading } = useETHPrice(priceFeedAddress);
  const TVL = Number(contractRudBalance) + contractBorrowBalance * ethPrice;

  const totalCollatRatio =
    totalRUDBorrowed / (contractBorrowBalance * ethPrice);
  const RUDTotalSupply = Number(rudTotalSupply).toFixed(MAX_DECIMAL_PLACE);
  const userDataUSD = {
    labels: USER_DATA_USD.map((data) => data.year),
    datasets: [
      {
        label: "User Profit",
        data: USER_DATA_USD.map((data) => data.profit),
        borderColor: "#02AAB0",
      },
    ],
    options: {
      legend: {
        display: false, // set display to false to hide the label
      },
    },
  };
  const userDataETH = {
    labels: USER_DATA_ETH.map((data) => data.year),
    datasets: [
      {
        label: "User Profit",
        data: USER_DATA_ETH.map((data) => data.profit),
        borderColor: "#02AAB0",
      },
    ],
    options: {
      legend: {
        display: false, // set display to false to hide the label
      },
    },
  };
  // State variable to identify the current clicked scene (USD/ETH)
  const [titleButtonVal, setTitleButtonVal] = useState("USD");
  const handleTitleButtonClick = (e) => {
    setTitleButtonVal(e.target.value);
  };

  const CardBody = () => (
    <div className={styles.protocolMainDiv}>
      <div className={styles.tvlMainDiv}>
        <div className={styles.radeMainInfo}>
          <div className={styles.logoNameContainer}>
            <img className={styles.radeLogo} src={Logo} alt="rade_logo" />
            <RadeTitle className={styles.radeHeading}>
              {HOME_ENUM.en.logo}
            </RadeTitle>
          </div>
          <div className={styles.tvlDiv}>
            <div className={styles.tvlKey}>{ADMIN_ENUM.tvl}</div>
            <div className={styles.tvlValue}>
              ${TVL.toFixed(MAX_DECIMAL_PLACE)}
            </div>
          </div>
          <div className={styles.chainInfoDiv}>
            <div className={styles.chainKey}>{ADMIN_ENUM.chain}</div>
            <div className={styles.chainNameDiv}>
              <div className={styles.chainName}>{ADMIN_ENUM.ethereumChain}</div>
              <div className={styles.chainTVL}>
                ${TVL.toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
          </div>
          <div className={styles.protocolDiv}>
            <div className={styles.protocol}>{ADMIN_ENUM.protocol}</div>
            <div className={styles.collateralrRatioDiv}>
              <div className={styles.collateralRatio}>
                {ADMIN_ENUM.collateralRatio}
              </div>
              <div className={styles.collateralPercentage}>
                {contractBorrowBalance === "0.0"
                  ? "-"
                  : totalCollatRatio.toFixed(MAX_DECIMAL_PLACE)}
                {contractBorrowBalance === "0.0" ? "" : "%"}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tvlGraphDiv}>
          <div className={styles.marketInfoDiv}>
            <div className={styles.buttonsDiv}>
              <Button
                value="USD"
                className={styles.usdButton}
                style={{
                  backgroundColor:
                    titleButtonVal === "USD" ? "#1D293A" : "transparent",
                }}
                onClick={handleTitleButtonClick}
              >
                {ADMIN_ENUM.usdButton}
              </Button>
              <Button
                value="ETH"
                className={styles.ethButton}
                style={{
                  backgroundColor:
                    titleButtonVal === "ETH" ? "#1D293A" : "transparent",
                }}
                onClick={handleTitleButtonClick}
              >
                {ADMIN_ENUM.ethButton}
              </Button>
            </div>
            <div className={styles.ethPriceDiv}>
              <div className={styles.ethPrice}>
                <img
                  src={etherLogo}
                  className={styles.etherLogo}
                  alt="ethereumIcon"
                />
                {ADMIN_ENUM.ethButton}
              </div>
              <div className={styles.priceValue}>
                ${ethPrice.toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.marketCapDiv}>
              <div className={styles.marketCapKey}>{ADMIN_ENUM.marketCap}</div>
              <div className={styles.marketCapValue}>$318,180,846,869</div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.tvlVolumeDiv}>
              <div className={styles.volume}>
                {ADMIN_ENUM.volume}
                <span className={styles.hour}>24h</span>
              </div>
              <div className={styles.volumeValue}>$2,318,180,846</div>
            </div>
          </div>
          <div>
            {titleButtonVal === "USD" ? (
              <LineChart
                chartData={userDataUSD}
                options={userDataUSD.options}
              />
            ) : (
              <LineChart
                chartData={userDataETH}
                options={userDataETH.options}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.nestAndStakeDetailsDiv}>
        <div className={styles.nestDetailsContainer}>
          <div className={styles.nestDetails}>
            <div className={styles.totalNestsKey}>{ADMIN_ENUM.totalNests}</div>
            <div className={styles.totalNestsValue}>
              {totalNumOfNests !== null ? (
                BigInt(totalNumOfNests._hex).toString()
              ) : (
                <Spinner />
              )}
            </div>
          </div>
          <div className={styles.nestDetails}>
            <div className={styles.totalActiveKey}>
              {ADMIN_ENUM.totalActive}
            </div>
            <div className={styles.totalActiveValue}>
              {totalNumOfNests && closedNests !== null ? (
                BigInt(totalNumOfNests._hex).toString() -
                BigInt(closedNests._hex).toString()
              ) : (
                <Spinner />
              )}
            </div>
          </div>
          <div className={styles.nestDetails}>
            <div className={styles.totalClosedKey}>
              {ADMIN_ENUM.totalClosed}
            </div>
            <div className={styles.totalClosedValue}>
              {closedNests !== null ? (
                BigInt(closedNests._hex).toString()
              ) : (
                <Spinner />
              )}
            </div>
          </div>
          {/* <div className={styles.nestDetails}>
            <div className={styles.totalDefaultsKey}>
              {ADMIN_ENUM.totalDefaults}
            </div>
            <div className={styles.totalDefaultsValue}>0</div>
          </div> */}
        </div>
        <div className={styles.stakedDetailsDiv}>
          <div className={styles.tokenStats}>{ADMIN_ENUM.tokenStats}</div>
          <div className={styles.tokensDetailsDiv}>
            <div className={styles.tokenDetails}>
              <div className={styles.rudSupplyKey}>{ADMIN_ENUM.rudSupply}</div>
              <div className={styles.rudSupplyValue}>{RUDTotalSupply}</div>
            </div>
            <div className={styles.tokenDetails}>
              <div className={styles.stakedRUDKey}>{ADMIN_ENUM.stakedRUD}</div>
              <div className={styles.stakedRUDValue}>{totalRadeInPool}</div>
            </div>
            <div className={styles.tokenDetails}>
              <div className={styles.vestedTokensKey}>
                {ADMIN_ENUM.vestedTokens}
              </div>
              <div className={styles.vestedTokensValue}>
                {totalVestedAmount}
              </div>
            </div>
          </div>
          <div className={styles.CDPStatsDiv}>{ADMIN_ENUM.CDPStats}</div>
          <div className={styles.tokensDetailsDiv}>
            <div className={styles.tokenDetails}>
              <div className={styles.pledgedETHKey}>
                {ADMIN_ENUM.pledgedETH}
              </div>
              <div className={styles.pledgedETHValue}>
                {(contractBorrowBalance * ethPrice).toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  if (loading) {
    return <Spinner />;
  }
  return CardBody();
};
export default Protocol;
