import cx from "classnames";
import { useContext, useState, useEffect } from "react";
import { AppContext, UserContext } from "../../context";
import moment from "moment";

import { RETURN_USER_STAKING } from "../../enums/returnUserStaking";
import { ethers } from "ethers";
import Progressbar from "../../components/ProgressBar/ProgressBar";
import { TOOL_TIP } from "../../enums/homeEnum";
import useRADEInWallet from "../../Hooks/useRADEVestDetails/useRADEVestDetails";
import vestingContract from "../../../src/artifacts/Contracts/stablecoin/VestingToken.sol/RADE.json";
import styles from "./index.module.scss";
import UpArrowIcon from "../../assets/UpArrow";
const StakeDetails = ({
  stakeId,
  stakedAmount,
  lockDuration,
  maturityDate,
  stakedTime,
  toggleStakeDetails,
  formattedRewardData,
}) => {
  const ETH_CONV = 10 ** 18;
  const MAX_DECIMAL_PLACES = 2;

  const { isMobile } = useContext(AppContext);
  function convertSecondsToTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let result = "";

    if (days > 0) {
      result += `${days} days `;
    }

    if (hours > 0) {
      result += `${hours} hours `;
    }

    if (minutes > 0) {
      result += `${minutes} minutes `;
    }

    if (remainingSeconds > 0) {
      result += `${remainingSeconds} seconds`;
    }

    return result.trim();
  }
  const handleOnClickArrow = () => {
    // Toggle the details for this row
    toggleStakeDetails(stakeId);
    // setRowClicked(false);
  };
  const AccordionBody = () => {
    return (
      <div className={styles.stakeDetailsContainer}>
        <div className={styles.headingContainer} onClick={handleOnClickArrow}>
          <div
            className={cx(styles.details, {
              [styles.detailsMob]: isMobile,
            })}
          >
            Account Details
          </div>
          <div>
            <UpArrowIcon />
          </div>
        </div>

        <div className={styles.detailsDiv}>
          <div className={styles.stakedRUDInfo}>
            <div className={styles.detailsHeading}>
              {RETURN_USER_STAKING.stakedRUD}
            </div>
            <div className={styles.stakeDetailsValue}>{stakedAmount}</div>
          </div>

          <div className={styles.vestingSchedule}>
            <div className={styles.detailsHeading}>
              {RETURN_USER_STAKING.lockDuration}
            </div>
            <div className={styles.stakeDetailsValue}>{lockDuration}</div>
          </div>

          <>
            <div className={styles.maturityDate}>
              <div className={styles.detailsHeading}>
                {RETURN_USER_STAKING.maturityDate}
              </div>
              <div className={styles.stakeDetailsValue}>{maturityDate}</div>
            </div>
            <div className={styles.maturityDateInMobile}>
              <div className={styles.stakedDate}>
                <div className={styles.detailsHeading}>
                  {RETURN_USER_STAKING.stakedDate}
                </div>
                <div className={styles.stakeDetailsValue}>
                  {moment
                    .unix(parseInt(stakedTime._hex, 16))
                    .format("DD-MM-YY HH:mm:ss")}
                </div>
              </div>
            </div>
          </>
        </div>

        <div
          className={cx(styles.stakingRewardsContainer, {
            [styles.stakingRewardsContainerMob]: isMobile,
          })}
        >
          <div className={styles.rewardContainer}>
            <div
              className={cx(styles.stakingRewards, {
                [styles.stakingRewardsMob]: isMobile,
              })}
            >
              {RETURN_USER_STAKING.stakingRewards}
            </div>
          </div>
          <div
            className={cx(styles.accruedRewards, {
              [styles.accruedRewardsMob]: isMobile,
            })}
          >
            {RETURN_USER_STAKING.accruedRewards}
          </div>

          <div
            className={cx(styles.accruedRewardsValue, {
              [styles.accruedRewardsValueMob]: isMobile,
            })}
          >
            {formattedRewardData[stakeId].userVestAmount
              ? (
                  formattedRewardData[stakeId].userVestAmount / ETH_CONV
                ).toFixed(MAX_DECIMAL_PLACES)
              : 0}
          </div>

          <div
            className={cx(styles.progressiveBarTooltipContainer, {
              [styles.progressiveBarTooltipContainerMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.progressiveBarContainer, {
                [styles.progressiveBarContainerMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.progressiveBarHeading, {
                  [styles.progressiveBarHeadingMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.rewardVested, {
                    [styles.rewardVestedMob]: isMobile,
                  })}
                >
                  {formattedRewardData[stakeId].totalRewardsPossible !== 0 &&
                  formattedRewardData[stakeId].userVestAmount &&
                  formattedRewardData[stakeId].totalRewardsPossible
                    ? (
                        formattedRewardData[stakeId].userVestAmount /
                        formattedRewardData[stakeId].totalRewardsPossible
                      ).toFixed(MAX_DECIMAL_PLACES)
                    : 0}
                  %
                </div>

                <div
                  className={cx(styles.progressiveBarDetailsValue, {
                    [styles.progressiveBarDetailsValueMob]: isMobile,
                  })}
                >
                  {" "}
                  Approx.{" "}
                  {formattedRewardData[stakeId].totalRewardsPossible
                    ? (
                        formattedRewardData[stakeId].totalRewardsPossible /
                        ETH_CONV
                      ).toFixed(MAX_DECIMAL_PLACES)
                    : 0}
                  <span className={styles.radeTokenText}>RADE Tokens</span>
                </div>
              </div>
              <div>
                {formattedRewardData[stakeId].totalRewardsPossible &&
                formattedRewardData[stakeId].userVestAmount ? (
                  <Progressbar
                    bgcolor="#02AAB0"
                    progress={
                      formattedRewardData[stakeId].totalRewardsPossible === 0
                        ? formattedRewardData[stakeId].userVestAmount /
                          formattedRewardData[stakeId].totalRewardsPossible
                        : 0
                    }
                    height={10.79}
                    width={50}
                  />
                ) : (
                  <Progressbar
                    bgcolor="#02AAB0"
                    progress={0}
                    height={10.79}
                    width={50}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <div
            className={cx(styles.stakedDateRemainingDaysContainer, {
              [styles.stakedDateRemainingDaysContainerMob]: isMobile,
            })}
          >
            <div className={styles.noOfDaysRemaining}>
              <div
                className={cx(styles.detailsHeading, {
                  [styles.detailsHeadingInMob]: isMobile,
                })}
              >
                {RETURN_USER_STAKING.noOfDaysRemaining}
              </div>

              <div
                className={cx(styles.stakeDetailsValue, {
                  [styles.stakeDetailsValueMob]: isMobile,
                })}
              >
                {convertSecondsToTime(
                  formattedRewardData[stakeId].vestTimestamp +
                    formattedRewardData[stakeId].vestingDuration -
                    formattedRewardData[stakeId].lastRewardTime
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.stakeDetailsContainer}>
      {/* calling as a function to avoid flickering issue */}
      {AccordionBody()}
    </div>
  );
};

export default StakeDetails;
