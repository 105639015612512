import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import "./LineChart.css";
function LineChart({ chartData, options }) {
  return (
    <div className="canvas-container">
      <Line data={chartData} options={options} className="canvas-chart" />
    </div>
  );
}

export default LineChart;
