import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAZBGdGxgjklODot-q1AWuctoMwL9_rLZQ",
  authDomain: "rade-a5338.firebaseapp.com",
  projectId: "rade-a5338",
  storageBucket: "rade-a5338.appspot.com",
  messagingSenderId: "702900980892",
  appId: "1:702900980892:web:b2ccaaac5ca66f3795b159",
  measurementId: "G-Y7DH7613YN",
};

const authProvider = new GoogleAuthProvider();

// Initialize Firebase app for radex
const radeApp = initializeApp(firebaseConfig);
const radeAuth = getAuth(radeApp);
const radeFirestore = getFirestore(radeApp);

// Export the necessary variables for each Firebase project
export { radeAuth, authProvider, radeFirestore };
