import React, { useEffect, useState, useMemo, useRef } from "react";
import Pagination from "../../components/Pagination/Pagination";
import searchIcon from "../../assets/searchIcon.svg";
import styles from "./index.module.scss";
import NestDetails from "./NestDetails/NestDetails";
import { formatBorrowUserDetailsData } from "./formatBorrowUserDetails";
import useBorrowedUserDetails from "../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import { radeFirestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { ethers } from "ethers";
import borrowContract from "../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import Spinner from "../../components/Spinner/Spinner";

/*global BigInt */

const Nests = () => {
  const [PZUserdata, setPZUserData] = useState([]); //state for storing data from API
  const [formattedData, setFormattedData] = useState(null);
  const [nextPaymentAmount, setNextPaymentAmount] = useState(null);
  const [nextPaymentDue, setNextPaymentDue] = useState(null);
  const [remainingNumOfTerm, setRemainingNumOfTerm] = useState(null);

  const [openRow, setOpenRow] = useState(null); // Track the clicked row
  const [userIds, setUserIds] = useState([]); // state to store the user IDs
  const [copyStatus, setCopyStatus] = useState("Copy"); //state variable to store the copied text
  const copyIdElement = useRef(null);
  const borrowedUserDetails = useBorrowedUserDetails(userIds);

  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(radeFirestore, "userData")
      );

      const result = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Access the document ID
        data: doc.data(), // Access the document data
      }));
      setPZUserData(result);
      const userIds = result.map((item) => item.id);
      setUserIds(userIds);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (userIds.length > 1) {
      setFormattedData(formatBorrowUserDetailsData(borrowedUserDetails));
    }
  }, [userIds, formattedData]);

  useEffect(() => {
    getPZUserData();
  }, []);

  //Function that handles copying text
  const copyToClipboard = (id) => {
    const textField = document.createElement("textarea");
    textField.innerText = id;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopyStatus("Copied!");

    setTimeout(() => {
      setCopyStatus("Copy"); // Revert to "Copy" after 3 seconds
    }, 3000);
  };

  // Function to handle row click and toggle the accordion
  const handleRowClick = (rowIndex) => {
    setOpenRow(openRow === rowIndex ? null : rowIndex);
    if (openRow === rowIndex) {
      setNextPaymentAmount(null);
      setNextPaymentDue(null);
      setRemainingNumOfTerm(null);
    }
  };
  if (!borrowedUserDetails || !formattedData || userIds.length < 1) {
    return <Spinner />;
  } else {
    return (
      <div>
        <div className={styles.transferOrders}>
          <div className={styles.searchBarDiv}>
            <input
              type="text"
              className={styles.searchBar}
              // value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
            />
            <img
              src={searchIcon}
              className={styles.searchIcon}
              alt="searchIcon"
            />
          </div>
          {/* <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          // totalCount={filteredData.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        /> */}
        </div>

        <table className={styles.tableContainer}>
          <thead>
            <tr>
              <th>Wallet ID</th>
              {/* <th>Username</th> */}
              <th>Pledged ETH</th>
              <th>Borrowed $RUD </th>
              <th>Loan Balance (RUD)</th>
              <th>Coll. Ratio</th>
              <th>Schedule</th>
            </tr>
          </thead>
          <tbody>
            {formattedData &&
              formattedData.map((post, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={styles.tableRow}
                    onClick={() =>
                      handleRowClick(
                        index,
                        setNextPaymentAmount(post.nextPaymentAmount),
                        setNextPaymentDue(post.nextPayment),
                        setRemainingNumOfTerm(post.remainingTerms)
                      )
                    }
                  >
                    <td
                      // ref={copyIdElement}
                      // onClick={() => copyToClipboard(post.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {post.account.slice(0, 6)}...${post.account.slice(-4)}
                      {/* {post.id && copyStatus !== "Copied!"
                    ? `${post.account.slice(0, 6)}...${post.account.slice(-4)}`
                    : copyStatus !== "Copied!" && "-"}
                  {copyStatus === "Copied!" && (
                    <span style={{ color: "#69788c" }}>{copyStatus}</span>
                  )} */}
                    </td>
                    {/* <td>{post.data.userName}</td> */}
                    <td>
                      {ethers.utils.formatEther(BigInt(post.collateralAmount))}
                    </td>

                    <td>{post.borrowedRUD}</td>
                    <td>{post.loanBalance}</td>
                    <td>{post.coll_Ratio}</td>
                    <td>{post.paymentSchedule}</td>
                  </tr>
                  {openRow === index && (
                    <tr className={styles.stakingDetails}>
                      <td colSpan="7">
                        <NestDetails
                          data={post}
                          borrowedUserDetails={borrowedUserDetails}
                          userIds={userIds}
                          index={index}
                          nextPaymentAmount={nextPaymentAmount}
                          nextPaymentDue={nextPaymentDue}
                          remainingNumOfTerm={remainingNumOfTerm}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default Nests;
