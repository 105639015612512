import React from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import attachmentLogo from "../../assets/attatchmentLogo.svg";
const UserDetails = ({
  user,
  handleClickStatusSwitch,
  loadingStates,
  selectedKYCId,
  handleBackButtonClick,
  setKYCRejectionReason,
}) => {
  // Render all other user details here
  return (
    <div>
      <div className={styles.userDetails}>
        <div>User Details:</div>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          Back
        </button>
      </div>

      <div className={styles.submittedKYCDetails}>
        <div className={styles.kycDetails}>
          {" "}
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>First Name:</div>
            <div className={styles.kycValue}>{user.data.firstName}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Last Name:</div>
            <div className={styles.kycValue}>{user.data.lastName}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Bank Account Type:</div>
            <div className={styles.kycValue}>{user.data.bankAccountType}</div>
          </div>
        </div>
        <div className={styles.kycDetails}>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Email:</div>
            <div className={styles.kycValue}>{user.data.email}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>DOB:</div>
            <div className={styles.kycValue}>{user.data.DOB}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Phone:</div>
            <div className={styles.kycValue}>{user.data.phone}</div>
          </div>
        </div>

        <div className={styles.kycDetails}>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Address Line 1:</div>
            <div className={styles.kycValue}>{user.data.addressLine1}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Address Line 2:</div>
            <div className={styles.kycValue}>{user.data.addressLine2}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Country:</div>
            <div className={styles.kycValue}>{user.data.country.label}</div>
          </div>
        </div>
        <div className={styles.kycDetails}>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>City:</div>
            <div className={styles.kycValue}>{user.data.city}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Region:</div>
            <div className={styles.kycValue}>{user.data.region.label}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Zip Code:</div>
            <div className={styles.kycValue}>{user.data.pincode}</div>
          </div>
        </div>
        <div className={styles.kycDetails}>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Account Number:</div>
            <div className={styles.kycValue}>{user.data.accountNumber}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>ABA Routing No.</div>
            <div className={styles.kycValue}>{user.data.ABARoutingNumber}</div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Bank Name</div>
            <div className={styles.kycValue}>{user.data.bankName}</div>
          </div>
        </div>
        <div className={styles.kycDetails}>
          {" "}
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>ID(Front Side):</div>
            <div className={styles.kycValue}>
              <a
                href={user.data.idFrontSide}
                target="_blank"
                className={styles.idFrontSideLink}
                rel="noopener noreferrer"
              >
                <img
                  src={user.data.idFrontSide}
                  className={styles.ID}
                  alt="attachment"
                />
              </a>
            </div>
          </div>
          <div className={styles.kycDetail}>
            <div className={styles.kycHeading}>Holding ID (Front):</div>
            <div className={styles.kycValue}>
              <a
                href={user.data.holdingPassport}
                target="_blank"
                className={styles.idFrontSideLink}
                rel="noopener noreferrer"
              >
                <img
                  src={user.data.holdingPassport}
                  className={styles.ID}
                  alt="attachment"
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.kycDetailContainer}>
          <div>
            <div className={styles.kycHeading}>Holding ID (Front):</div>
            <div className={styles.kycValue}>
              <a
                href={user.data.holdingPassport}
                target="_blank"
                className={styles.idFrontSideLink}
                rel="noopener noreferrer"
              >
                <img
                  src={user.data.holdingPassport}
                  className={styles.ID}
                  alt="attachment"
                />
              </a>
            </div>
          </div>
          <div>
            <div className={styles.kycHeading}>Holding ID (Back):</div>
            <div className={styles.kycValue}>
              <a
                href={user.data.holdingPassportBackside}
                target="_blank"
                className={styles.idFrontSideLink}
                rel="noopener noreferrer"
              >
                <img
                  src={user.data.holdingPassportBackside}
                  className={styles.ID}
                  alt="attachment"
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {" "}
          <ButtonRade
            customStyling={`${styles.statusSwitch} ${
              loadingStates[user.email] ? styles.disabledButton : ""
            }`}
            disabled={loadingStates[user.email]} // Disable the button when loading is true
            onClick={() => handleClickStatusSwitch(selectedKYCId)}
          >
            Change KYC Status
          </ButtonRade>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
